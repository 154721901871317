<template>
  <custom-dialog
    :dialog="dialog"
    @outsideClick="$emit('update:dialog', false)"
  >
      <DialogTitle :title='$vuetify.lang.t("$vuetify.productList1")'/>

      <v-card-text class="pb-0">
        <v-form v-model="valid" ref="form" lazy-validation>
          <v-container fluid>
            <v-row>
              <v-col class="py-0" cols="12" lg="6" md="6" sm="6">
                <v-select
                  :items="category"
                  :label="$vuetify.lang.t('$vuetify.productCategory')"
                  :rules="generalRule"
                  v-model="categoryValue"
                  :readonly="readOnly"
                />
              </v-col>

              <v-col class="py-0" cols="12" lg="6" md="6" sm="6">
                <v-select
                  :items="unit"
                  :item-text="(item) => item.label"
                  :item-value="(item) => item.value"
                  :label="$vuetify.lang.t('$vuetify.productUnit')"
                  :rules="generalRule"
                  v-model="unitValue"
                  :readonly="readOnly"
                >
                </v-select>
              </v-col>

              <v-col class="py-0" cols="12" lg="6" md="6" sm="6">
                <v-text-field
                  :label="$vuetify.lang.t('$vuetify.goodsCode')"
                  :rules="generalRule"
                  v-model="myObj.other_code"
                  :readonly="readOnly"
                />
              </v-col>

              <v-col class="py-0" cols="12" lg="6" md="6" sm="6">
                <v-text-field
                  :label="$vuetify.lang.t('$vuetify.goodsName')"
                  :rules="generalRule"
                  v-model="myObj.name"
                  :readonly="readOnly"
                />
              </v-col>

              <v-col cols="12" class="pb-0 pt-0">
                <v-text-field
                  :label="$vuetify.lang.t('$vuetify.remarks')"
                  v-model="myObj.note"
                  :readonly="readOnly"
                />
              </v-col>

              <v-col cols="12" class="py-0 mt-4" v-if="!readOnly">
                <v-file-input
                  label="File"
                  v-model="files"
                  multiple
                  counter
                  placeholder="Select your files"
                  prepend-inner-icon="mdi-paperclip"
                  prepend-icon=""
                  outlined
                  :show-size="1000"
                  accept="image/jpeg, image/png"
                >
                  <template v-slot:selection="{ index, text }">
                    <v-chip
                      v-if="index < 3"
                      color="deep-purple accent-4"
                      dark
                      label
                      small
                    >
                      {{ text }}
                    </v-chip>

                    <span
                      v-else-if="index === 3"
                      class="overline grey--text text--darken-3 mx-2"
                    >
                      +{{ files.length - 2 }} File(s)
                    </span>
                  </template>
                </v-file-input>
              </v-col>

              <v-col cols="12" class="pb-4" v-if="displayFiles.length != 0">
                <v-sheet
                  elevation="2"
                  class="py-4 pl-2 d-flex"
                  style="overflow-x: auto"
                >
                  <div
                    v-for="(item, key) in displayFiles"
                    :key="key"
                    class="mx-2"
                  >
                    <v-img
                      :src="imageURL + item"
                      height="100px"
                      width="100px"
                    />

                    <div class="d-flex justify-center mt-2" v-if="!readOnly">
                      <v-btn color="red" text @click="removeFile(item)">
                        <v-icon>mdi-trash-can</v-icon>
                      </v-btn>
                    </div>
                  </div>
                </v-sheet>
              </v-col>
            </v-row>

            <v-row v-if="myObj.type_code == '1001'">
              <v-col class="py-0" cols="12" lg="4" md="6" sm="6">
                <v-text-field
                  label="Short Name"
                  :rules="generalRule"
                  v-model="myObj.param_data[1001]"
                  :readonly="readOnly"
                />
              </v-col>

              <v-col class="py-0" cols="12" lg="4" md="3" sm="6">
                <v-text-field
                  label="Valid Period"
                  :rules="generalRule"
                  v-model="myObj.param_data[1002]"
                  :readonly="readOnly"
                />
              </v-col>

              <v-col class="py-0" cols="12" lg="4" md="3" sm="6">
                <v-text-field
                  label="Cargo Space"
                  :rules="generalRule"
                  v-model="myObj.param_data[1003]"
                  :readonly="readOnly"
                />
              </v-col>

              <v-col class="py-0" cols="12" lg="6" md="3" sm="6">
                <v-text-field
                  label="Model"
                  :rules="generalRule"
                  v-model="myObj.param_data[1004]"
                  :readonly="readOnly"
                />
              </v-col>

              <v-col class="py-0" cols="12" lg="6" md="3" sm="6">
                <v-text-field
                  label="Color"
                  :rules="generalRule"
                  v-model="myObj.param_data[1005]"
                  :readonly="readOnly"
                />
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </v-card-text>

      <v-card-actions class="pb-8">
        <v-spacer />

        <v-btn color="red darken-2" outlined @click="cancelForm">
          {{ $vuetify.lang.t("$vuetify.cancel") }}
        </v-btn>

        <v-btn color="green darken-2" @click="validate" v-if="!readOnly">
          {{ $vuetify.lang.t("$vuetify.save") }}
        </v-btn>

        <v-spacer v-if="$vuetify.breakpoint.xsOnly" />
      </v-card-actions>
  </custom-dialog>
</template>

<script>
import { goodsTypeList } from "@/api/goods";
import { profilePicUpload } from "@/api/company";
import serverConfig from "@/utils/serverConfig";
import { draggable } from "@/api/customFunction";

async function getFileFromUrl(url, name, defaultType = "image/jpeg") {
  const response = await fetch(url);
  const data = await response.blob();
  return new File([data], name, {
    type: response.headers.get("content-type") || defaultType,
  });
}

function filterType(parent, child, returnArr) {
  if (child != undefined) {
    for (let i = 0; i < child.length; i++) {
      returnArr.push({
        text: parent + child[i].name,
        value: child[i].code,
      });
      if (child[i].items != null) {
        filterType(parent + child[i].name + " / ", child[i].items, returnArr);
      }
    }
  }
  return returnArr;
}

export default {
  name: "ProductCRUD",
  props: { dialog: Boolean, myObj: Object, imageList: Array },
  components: {
    ImageUpload: () => import("@/components/imageUpload"),
    DialogTitle: () => import("@/components/dialogTitle"),
  },
  data() {
    return {
      readOnly: !this.permissionCheck("modify"),
      valid: false,
      imageURL: serverConfig.img_url,
      blankIMG: serverConfig.blank_img,
      generalRule: [(v) => !!v || "this field is required"],
      category: [],
      cloneCategory: [],
      files: [],
      unit: [
        {
          value: this.$vuetify.lang.t("$vuetify.Bottles"),
          label: this.$vuetify.lang.t("$vuetify.Bottles"),
        },
        {
          value: this.$vuetify.lang.t("$vuetify.Pcs"),
          label: this.$vuetify.lang.t("$vuetify.Pcs"),
        },
        {
          value: this.$vuetify.lang.t("$vuetify.KG"),
          label: this.$vuetify.lang.t("$vuetify.KG"),
        },
        {
          value: this.$vuetify.lang.t("$vuetify.Cup"),
          label: this.$vuetify.lang.t("$vuetify.Cup"),
        },
        {
          value: this.$vuetify.lang.t("$vuetify.Glass"),
          label: this.$vuetify.lang.t("$vuetify.Glass"),
        },
        {
          value: this.$vuetify.lang.t("$vuetify.cm"),
          label: this.$vuetify.lang.t("$vuetify.cm"),
        },
        {
          value: this.$vuetify.lang.t("$vuetify.inch"),
          label: this.$vuetify.lang.t("$vuetify.inch"),
        },
        {
          value: this.$vuetify.lang.t("$vuetify.layer"),
          label: this.$vuetify.lang.t("$vuetify.layer"),
        },
        {
          value: this.$vuetify.lang.t("$vuetify.degrees"),
          label: this.$vuetify.lang.t("$vuetify.degrees"),
        },
      ],
    };
  },
  methods: {
    drag() {
      draggable();
    },
    validate() {
      if (this.$refs.form.validate()) {
        const arr = [];
        var temp = [];

        if (this.myObj.image.length > 0)
          this.myObj.image.forEach((p) => temp.push(p));

        this.files.forEach((p) => {
          arr.push(this.uploadFile(p));
        });

        Promise.all(arr)
          .then((res) => {
            res.forEach((p) => temp.push(p));
          })
          .finally(() => {
            this.myObj.image = temp.join(",");
            this.$emit("handleData", this.myObj);
            this.files = [];
          });
      }
    },
    reset() {
      this.$refs.form.reset();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
    cancelForm() {
      this.files = [];
      this.resetValidation();
      this.$emit("update:dialog", false);
    },
    removeFile(data) {
      this.imageList.splice(this.imageList.indexOf(data), 1);
    },
    async uploadFile(file) {
      var arr = "";

      const fileForm = new FormData();
      fileForm.append("file", file);

      await profilePicUpload(fileForm)
        .then((res) => {
          arr = res.data.md5;
        })
        .catch((err) => console.log("Finanical File Error", err));

      return arr;
    },
  },
  mounted() {
    goodsTypeList().then((res) => {
      this.category = [...filterType("", res.items, [])];
      this.cloneCategory = [...res.items];
    });
  },
  computed: {
    profilePic: function () {
      return this.myObj.image != ""
        ? serverConfig.img_url + this.myObj.image
        : serverConfig.blank_img;
    },
    displayFiles: function () {
      const result = [];

      this.imageList.forEach((img) => {
        if (img != "") {
          result.push(img);
        }
      });

      return result;
    },
    categoryValue: {
      get: function () {
        return this.category.filter(
          (p) => p.value.toLowerCase() == this.myObj.type_code.toLowerCase()
        )[0];
      },
      set: function (value) {
        this.myObj.type_code = value;
      },
    },
    unitValue: {
      get: function () {
        return this.unit.filter(
          (p) => p.value.toLowerCase() == this.myObj.unit.toLowerCase()
        )[0];
      },
      set: function (value) {
        this.myObj.unit = value;
      },
    },
  },
};
</script>
